:root {
    --padding: 2%; /* Must be a percentage value if you want IE10 compatability */
    --margin: 1%; /* Must be a percentage value if you want IE10 compatability */
    --vertical-padding: 2em; /* must NOT be a percentage value */
    --vertical-margin: 2em; /* must NOT be a percentage value */
    --breakpoint-m: 1100px;
    --breakpoint-s: 800px;
    --breakpoint-xs: 550px;
    --breakpoint-menu: 800px;
    --container-max-width: 1100px;
    --fixed-header-height: 80px;
}

@import "/cssmodules/normalize.css";
@import "/cssmodules/leaflet2.css";
@import "/cssmodules/basic_styling.css";
@import "/theme/intendit/css/modules/flex_grid.css";
@import "/cssmodules/slick.css";
@import "/cssmodules/slick-theme.css";
@import "/cssmodules/menu.css";
@import "/cssmodules/font-awesome.css";
@import "/cssmodules/magnific-popup.css";
@import "/theme/intendit/css/modules/fade_zoom.css";
@import "/fontmodules/Roboto.css";
@import "/fontmodules/NewsCycle.css";

/*
@import "/fontmodules/ABeeZee.css";
@import "/cssmodules/photoswipe.css";
@import "/cssmodules/animate.css";
*/
 
h3.scroll {font-size:1.2em; color:#A91B1B; font-weight:400; }

.infoblad {padding: 5px 20px 7px;  min-width:350px; display:table;   background:#d0d0d0; transition:background 0.5s ease-in 0s; margin: 0 0 3px}
.infoblad:hover { background:#bebebe; transition:background 0.5s ease-in 0s;}



/*-▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮-*/
/*----------------------------------------------------------------GENERAL-----------------------------------------------------------------------*/
body {font-weight: 400; 
    background:#e4e4e4; 
      color: #202020;
      line-height:1.4em;
      font-family: 'NewsCycle', sans-serif;
    background:#fff;
    }
 
body.startsida  { background: url('/theme/intendit/graphics/bg.jpg') center top/cover no-repeat fixed;}


/*---------------------------------------------------------------DIVERSE "WRAPPERS"------------------------------------------------------------*/
.wrapper { min-height: 100vh; margin-top:115px; } 
.startsida .wrapper {min-height:1em; }
.container {width:100%; margin: 0 auto; }
.container.lrg {max-width: 1300px;}
.sida {padding: 40px 0 50px; } 

/*---------------------------------------------------------------BASIC ELEMENTS----------------------------------------------------------------*/
a, a:hover {color:#404040; font-weight:300; text-decoration: none;}
p {margin:3px 0 20px;}
strong {font-weight:500;}
iframe {max-width:100%;}

/*-------------------images--------------------*/
img {max-width:100%; height:auto;}
.bild {border-bottom: 2px solid #e4e4e4;
    border-left: 2px solid #e4e4e4;
    float: right;
    height: auto;
    margin: 0 0 30px 30px;
    max-width: 40%;
    overflow: hidden;
    padding: 0 20px 20px;
    width: 340px; }
.box_bild, .listing_bild {width:100%; flex:none; max-width:100%; min-height:160px; background-size:cover; background-position:center center; } /*säkra vyn i IE*/
.bild img, .box_bild img, .listing_bild img, .galleri_bild img {width:100%; height:auto; } /*säkra vyn i IE*/

/*-------------------listor--------------------*/
.sida ul {list-style:disc; padding: 0 0 0 15px; }
.sida ul li {margin-bottom:3px;  }
/*.sida ul {list-style:none; padding: 0; }
.sida ul li:before {content: "\f00c"; margin:0 10px 0 0; font-family: FontAwesome;}*/

/*------------readmore & buttons----------------*/
.button, .readmore, .boltforms-row button {background: rgba(0, 0, 0, 0.15); padding: 6px 0 8px; display:block; margin:0; text-align: center; border-radius:2px; line-height: 1.2em; border:none; transition:background 0.5s ease-in 0s;}
.readmore { width:100px;} .button, .boltforms-row button  { width:100%;}  
.button:hover, .readmore:hover, .boltforms-row button:hover {background:rgba(0, 0, 0, 0.3); transition:background 0.5s ease-in 0s;}
.boltforms-row button, .boltforms-row button:hover { color:#fff; }
button.slick-arrow {background:none;}


/*-----------------ikoner-----------------------*/
.fa.round {border-radius: 50%; padding: 11px 0 0; text-align: center; width: 45px; height: 45px; font-size:1.5em;background:rgba(0, 0, 0, 0.15);}
.fa.circle {border-radius: 50%; padding: 12px 0 0; text-align:center; width: 50px; height: 50px; font-size:1.5em; border: 1px solid #fff; color:#fff; display:block;}

.toptext .fa.round { padding: 13px 0 0; margin: 0 0 6px 6px; background: rgba(0, 0, 0, 0.5); color: #ffffff; height: 50px; width: 50px; /*clear:both; display:block;*/}
.sidfot .fa.round {background:rgba(255, 255, 255, 0.15); font-size:1.3em; width: 40px; height: 40px; padding: 11px 0 0;  }
.fadebox_textdiv .fa.circle { clear:both; margin: 10px auto 0; }
 
/*-------------egna klasser---------------------*/
.spaltX2 {column-count: 2; column-gap: 50px; text-align:left !important;} /*Delar upp i 2 spalter*/
.shadow {box-shadow: 1px 1px 5px rgba(0, 0, 0, 0.3) }
.shadowborder {box-shadow: 0px 0px 0px 1px #e4e4e4;}
.pubdatum {color:#909090; font-size:95%; display:block; clear:both;  }
.record {border-bottom:1px solid #e4e4e4; }

/*---------------------------------------------------------------FONTS------------------------------------------------------------------------*/
h1, h2, h3, h4, h5, h6 {margin: 0; letter-spacing:0; line-height:1.1em; padding: 0px; font-weight:600; font-family: 'Roboto', sans-serif; }
h1{font-size: 2.4em;padding: 0px 0 20px; /*text-transform:uppercase;*/ position:relative; }
h2{font-size: 1.7em;}
h3{font-size: 1.3em;}
h4{font-size: 1em;}
h5{font-size: 1em;}

.highlight {font-size:4em; line-height:1.2em; padding: 20px 0 ; color:#e4e4e4; font-weight:300; }
.sida.listing h1 {padding-bottom:40px; margin-bottom:40px; border-bottom:1px solid #e4e4e4;}
 
 


/*-▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮-*/
/*----------------------------------------------------STARTSIDAN various------------------------------------------------------------------------*/ 
.toptext { position:fixed; right:10px; top:10px; z-index:11; text-align:right;     } /*display none by default*/
.toptext a {color:#b1b1b1; }
.toptext .fa {padding:0 5px 0 20px;  }
.topbild {height:30vh; max-height:45vw; background-position: center center; background-size:cover; position: relative; border-bottom:5px solid #e4e4e4; }
 
/*----------------------Startsidatext-----------------------*/
.startsidatext {padding: 40px 0 20px; width:100%;  background: rgba(255, 255, 255, 0.6); }
.startsidatext .container {  padding: 0 0px 20px; }
.startsidatext h1 {padding:0 0 0px; text-align:center; font-size:3em; text-transform:lowercase; letter-spacing:1pt; }
.startsidatext_textdiv {text-align:center; font-size:1.35em; line-height:1.3em;  } 

/*----------------------Boxar-------------------------------*/
.boxar {padding: 30px 0 40px; }
.box, .produktkategori, .underkategori, .produkt { min-height:200px; position:relative;  }
.box h2, .produktkategori h2, .underkategori h2, .produkt h2{text-align:center; font-size:1.2em;      background:#fff; padding: 7px 0;  border-top:5px solid #A91B1B; }
.box h2 .fa, .produktkategori h2 .fa, .underkategori h2 .fa , .produkt h2 .fa {font-size:0.9em; color:#b9b9b9; }
.box:after, .produktkategori:after, .underkategori:after, .produkt:after {content:""; width:100%; position:absolute; height:20px; 
 background: url('/theme/intendit/graphics/shadow.png') center top/100% auto no-repeat ;}

 


/*-▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮-*/
/*---------------------------------------------------------------GALLERI ------------------------------------------------------------------*/
.magnificgalleri, .wrapper .loggagalleri {padding: 30px 0 0; margin-top:10px; border-top: 1px solid #d0d0d0; }
.galleri_bild a {display:block; }
.galleri_bild {box-shadow: 1px 1px 0 0 #e4e4e4;} .galleri_bild:nth-child(5n + 5) {box-shadow: 0px 1px 0 0 #e4e4e4;}
.galleri_bild img {padding:20px 20px 0px;}
.thumbtitel {font-size:90%; line-height:1.3em;  padding: 0 20px 20px;}  

/*logo gallery*/
.loggagalleri .logga {min-height: 70px; } 
.loggagalleri .logga div {text-align:center; width: 100%; flex:0 0 auto;} 
.loggagalleri .logga img {height:auto; }
.standing  {width:40%; } .squarish  {width:40%; } .landscape  {width:55%; }.longlandscape {width:70%; }.extralonglandscape {width:100%; }

/*magnific styling*/ 
.mfp-bg {background: #000; opacity:0.9;}   
.mfp-arrow, .mfp-arrow:before,.mfp-arrow:after {border:none;  height:100px; color:#fff; font-size:60px; font-family: FontAwesome; opacity:0.9; }
.mfp-arrow-left:before {content:"\f104"; } .mfp-arrow-right:before {content:"\f105"; }
button.mfp-close { font-size: 3.4em; margin:0 -2px 0 0; opacity: 0.9;}
 

/*-▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮-*/
/*--------------------------------------------------------------KONTAKT SAKER------------------------------------------------------------------*/
/*Kontaktbanner & Formulär*/
.boltforms-row label, .boltforms-row button {font-weight: 400; margin-bottom: 0;}
.boltforms-row input, .boltforms-row textarea {width:100%; color:#606060;  }

.kontaktbanner {background:#a91b1b; padding:50px 0; color:#fff; box-sizing: border-box;}
.kontaktbanner h2 { padding:0px 0 10px; }
.kontaktbanner .boltforms-row {float:left; width:33.3%; padding-right:10px;}
.kontaktformular .boltforms-row, .kontaktbanner .boltforms-row:nth-child(n+4) {width:100%;}

/*Googlekarta*/
.map-canvas {height: 35vh; margin-top:2em;}
.map-canvas img {max-width: none;}

/*Sifoten*/
.sidfot {padding: 50px 0 200px; width:100%; background:#000;}
.sidfot, .sidfot a {color:#b0b0b0; font-weight:300; }
.sidfot h3 {padding: 0 0 5px;} 


/*-▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮-*/
/*----------------------------------------------------------------HEADER SPECIFIC---------------------------------------------------------------*/
header {text-transform:none; width:100%; background:#fff; z-index:10; position:fixed; top:0;  min-height:115px; }
body.scrolled header {}

/*Logotype*/
.logo { padding: 10px 15px 5px 20px; box-sizing:border-box; } 
.logo img {max-height: 50px; width:auto; max-width:calc(100vw - 100px)}
.logo a {padding:0; display:block;}  

/*Standard meny + hovers*/
nav {background:#A91B1B; } 
header nav ul.menu > li {padding: 0 10px; margin:0; border-right:1px solid #e4e4e4;}
nav ul.menu > li:last-child {border:none; }
nav ul.menu li a { padding: 10px 15px; margin:0; letter-spacing:0; color:#fff; /*transition:color 0.5s ease-in 0s;*/}
nav ul.menu li a:hover, nav ul.menu li.active a { color:#fff; }
nav ul.menu li.dropdown > a:after { content: " \f107"; display: inline-block; font-family:FontAwesome; font-size: 1em; margin: 0 0 0 5px; vertical-align: middle;}
nav .menu .dropdown ul  {border:none; padding: 10px 0; left:20px; }
.sub-menu-items li a { padding: 5px 15px !important; }



nav ul.menu li a.newwindow:after {content: "\f14c"; margin:0 0px 0 10px; font-family: FontAwesome; font-size:0.9em; } 

/*Mobile menu under 800px - fixed header*/
.mobile-menu nav { background:none; }
.mobile-menu .toptext { display:none; }
.mobile-menu nav ul.menu li a {  color:#404040; /*transition:color 0.5s ease-in 0s;*/}

.mobile-menu header {position:fixed; top:0; min-height:80px; box-shadow:none; flex-wrap:nowrap; border-bottom: 1px solid #e4e4e4;} /*background på header bar*/
.mobile-menu .wrapper {margin-top:80px;}  /*knuffar ner innehåll bakom menyn - justera höjd*/
.mobile-menu nav ul.menu {margin-top:1px; background:rgba(255, 255, 255, 0.9); }
.mobile-menu nav ul.menu.show { max-height:calc(100vh - 80px); }  /*räknar höjd - fixed header*/
.mobile-menu nav ul.menu li, .mobile-menu nav ul.menu li a, .mobile-menu nav .menu .dropdown ul {border:none; background:none; }
.mobile-menu nav .menu .dropdown ul {padding:0; left:0px;} 
.mobile-menu nav ul.menu li.dropdown > a:after {display:none; }
 
nav #menu-icon {padding: 20px 20px 20px 0;}
nav #menu-icon span {border-radius:3px; border: 2px solid #000;}

 


/*-▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮-*/
/*----------------------------Allt under 1300px----------------------------*/
@media (max-width: 1300px) 
{  
/* Standard meny - tight it up*/ 
nav ul.menu li {padding:0 5px; }      
nav ul.menu li a {}    
}


/*----------------------------Allt under 1100px----------------------------*/
@media (max-width: var(--breakpoint-m))
{
/*Containers*/
.container, .container.lrg {width: 98%;}  
header.container, header.container.lrg {width: 100%;}  
}
/*----------------------------Allt under 800px----------------------------*/
@media (max-width: var(--breakpoint-s))
{
/*Fontsize*/ 
body { line-height:1.25em; font-size: 0.97em; }
    
/*Slider height*/  
.slick-slide {min-height:55vw;}

/*gallerithumbs*/
.galleri_bild:nth-child(3n+3) {box-shadow: 0 1px 0 0 #e4e4e4;}
.galleri_bild:nth-child(5n+5) {box-shadow: 1px 1px 0 0 #e4e4e4;}  
    
/*Bild*/
.bild { margin: 0 0 20px 20px;  padding: 10px;}      
   
/*Sidfot*/   
.sidfot .s-twelve {border-bottom: 1px solid #222222;margin: 0 auto;max-width: 400px; padding: 20px 0;text-align: center; }
.sidfot .s-twelve:last-child {border-bottom: none; }   
}


/*---------------------------MEDIA XS - upp till 550-----------------------------*/
@media (max-width: var(--breakpoint-xs))
{
  
.startsidatext h1 {
    font-size: 2.4em;}
     
    
    
.topbild {display:none; }   
    
/*bild*/
.bild {width:100%; max-width:100%; margin: 0 0 20px; float:none; padding:20px ;border: 2px solid #e4e4e4; }     
.bild img {width:100%; height:auto; }  
    
    
/*spalt*/    
.spaltX2 {-webkit-column-count: 1; -moz-column-count: 1; column-count: 1; -webkit-column-gap: 0px; -moz-column-gap: 0px; column-gap: 0px; text-align:none;} 
   
/*Kontaktbanner*/   
.kontaktbanner .boltforms-row {width:100%; padding-right:0px;}

/*gallerithumbs*/
.galleri_bild:nth-child(3n+3) {box-shadow: 1px 1px 0 0 #e4e4e4;} 
.galleri_bild:nth-child(2n+2) {box-shadow: 0 1px 0 0 #e4e4e4;} 
.galleri_bild img {padding: 13px;}    
    
/*map*/
.map-canvas {width:100%; max-height:200px; }
    
/*table varje cell under varann med border efter varje rad 
table tr td {display: list-item; list-style: none; padding:0; }
table tr td:first-child {padding: 10px 0 0;}
table tr td:last-child {padding: 0 0 10px;}*/     
}

@media (max-width: 350px)
{
  
}

/*-------------------------------------------------------------------END MEDIA QUERYS--------------------------------------------------------------------*/




 

/* --------------------------------------------------------Sliding border under text--------------------------------------------------------- 
.box h2 {padding-bottom: 35px; position: relative; text-align: center;}
.box h2:after {content:""; position:absolute; background:#404040; height:3px; width:20px; margin: 45px 0 0; left:50%; transition: width 0.5s ease-in 0s; transition: margin 0.5s ease-in 0s; }
.box:hover h2:after { width:150px;  margin:50px 0 0 -75px; }*/

/* ------------------------------------------------------------Tables--------------------------------------------------------------------------
table { margin:30px 0; font-size:95%; }
table tr {width:100%; border-bottom: 1px solid #e4e4e4;}
table tr td {margin: 0; padding:5px 30px 5px 0; vertical-align: top; border:none; }
table tr td:last-child {padding:5px 0px 5px 0;}

/* ------------------------------------------------------------Sökning--------------------------------------------------------------------------
.searchbox {}
#searchform {margin:0; }
#searchform input {margin:0; height: 23px; padding: 3px 10px; color:#959595; display: inline-block; }
.search {float: none;padding: 0;}*/

/* ------------------------------------------------------------Egen menu -icon-----------------------------------------------------------------
#menu-icon span {border:none; }
#menu-icon  {background-image: url("/theme/intendit/icons/mobile-menu-icon.png"); background-position: center right; background-repeat: no-repeat;
background-size: 100% auto; height: 30px; width: 40px;}*/


/*------------------------------------------------------Make FB widget responsive-------------------------------------------------------*/
.fb_iframe_widget, .fb_iframe_widget span, .fb_iframe_widget iframe[style], .twitter-timeline {width: 100% !important;}
